import React from "react";
import Layout from "components/layout.component";
import { useConfig } from "hooks/use-config.hook";
import { translationHelper } from "helpers/translation.helper";
import { useTranslation } from "react-i18next";

const CookiePolicyPage: React.FC = (props) => {
    const { i18n } = useTranslation();

    const config = useConfig();

    if (i18n.language !== "fi") {
        return <Layout>
            <div className="prose bg-white overflow-hidden shadow sm:rounded-md bg-white w-full max-w-full">
                <div className="px-4 py-4 sm:px-6">
                    <h1>This page is currently available only in Finnish. Please see the Finnish version of the
                        page.</h1>
                </div>
            </div>
        </Layout>;
    }

    return (
        <Layout>
            <div className="prose bg-white overflow-hidden shadow sm:rounded-md bg-white w-full max-w-full">
                <div className="px-4 py-4 sm:px-6">
                    <h1>Evästekäytännöt ja yksityisyys verkkopalvelussa</h1>

                    <p>
                        Tästä selosteesta löydät tietoja verkkopalvelun{" "}
                        <b>{process.env.GATSBY_WEBSITE_URL}</b>{" "}
                        evästekäytännöistä sekä yksityisyydestäsi käyttäessäsi
                        verkkopalvelua. Me, {config.organizer.name}{" "}
                        ("Tapahtumajärjestäjä") ja Eventio Oy ("Eventio"),
                        tarjoamme verkkopalvelun keskinäiseen sopimukseemme
                        perustuen, jossa Eventio on tekninen palveluntuottaja
                        sekä tietojen käsittelijä ja Tapahtumajärjestäjä
                        rekisterinpitäjä.
                    </p>

                    <h2>Evästeet</h2>

                    <p>
                        Käytämme tämän verkkopalvelumme tuottamiseksi pitkä- ja
                        lyhytaikaisia evästeitä. Käytämme yksinkertaisuuden
                        vuoksi termiä "eväste" varsinaisten evästeiden
                        ("cookies") rinnalla myös muihin niitä vastaavien
                        tekniikoiden käyttöön, kuten selaimen oman
                        paikallismuistin ("local storage" ja "session storage")
                        käyttöön.
                    </p>

                    <p>
                        Evästeiden avulla voimme tallentaa selaimesi muistiin
                        lyhyitä tekstimuotoisia tietoja, kuten tekemäsi
                        kielivalinnan tai istuntotunnisteen. Selaimesi lähettää
                        evästeen takaisin meille käyttäessäsi verkkopalvelua,
                        jolloin voimme mukauttaa palvelun toimintaa ja sisältöä
                        sinulle soveltuvaksi.
                    </p>

                    <p>
                        Evästeiden avulla keräämme myös tietoja eri
                        käyttötarkoituksia varten. Kerättävistä tiedoista löydät
                        lisätietoa{" "}
                        <a
                            href={`${process.env.GATSBY_WEBSITE_URL}/privacy-policy`}
                        >
                            Tietosuojaselosteesta
                        </a>
                        .
                    </p>

                    <p>
                        Sinulla on mahdollisuus hallita evästeiden käyttöä
                        käyttämäsi selaimen evästeasetuksista.
                    </p>

                    <h3>Lyhytaikaiset evästeet</h3>

                    <p>
                        Lyhytaikaisilla evästeillä tarkoitamme evästeitä, jotka
                        poistuvat aina kun suljet selaimen. Verkkopalvelua
                        käyttäessäsi voimme asettaa seuraavat{" "}
                        <b>lyhytaikaiset evästeet</b> selaimeesi:
                    </p>

                    <ul>
                        <li>
                            <b>Istuntotunniste</b>, jonka avulla ylläpidämme
                            mahdollista ostoskoriasi. Istuntotunnisteen
                            perusteella tallennamme myös oston, varauksen tai
                            ilmoittautumisen yhteydessä antamasi lisätiedot. Jos
                            olet kirjautuneena verkkopalveluun,
                            istuntotunnisteen avulla tunnistamme sinut ollessasi
                            kirjautuneena ja voimme tarjota sinua koskevia
                            tietoja.
                        </li>
                    </ul>

                    <p>
                        Lyhytaikaiset evästeet ovat pakollisia, jotta
                        verkkopalvelumme toimii asianmukaisesti. Siksi
                        lyhytaikaisten evästeiden salliminen on tarpeellista
                        verkkopalvelumme käyttämiseksi.
                    </p>

                    <h3>Pitkäaikaiset evästeet</h3>

                    <p>
                        Pitkäaikaisilla evästeillä tarkoitamme evästeitä, jotka
                        säilyvät selaimesi muistissa vaikka suljet selaimen.
                        Näin saamme saman evästeen käyttöömme myös seuraavalla
                        käyttökerralla ja voimme tämän perusteella tietyissä
                        tapauksissa tunnistaa sinut samaksi käyttäjäksi kuin
                        aiemmalla kerralla. Verkkopalvelua käyttäessäsi voimme
                        lisäksi asettaa seuraavat <b>pitkäaikaiset evästeet</b>{" "}
                        selaimeesi:
                    </p>

                    <ul>
                        <li>
                            <b>Logrocket</b> -analytiikkapalvelun
                            seurantaevästeen, jonka avulla seuraamme
                            palveluidemme käyttöä ja mahdollisia
                            virhetilanteita, sekä satunnaisten käyttäjien toimia
                            verkkopalvelussamme. Tiedot poistetaan 14 päivän
                            kuluttua istunnosta.
                        </li>
                        <li>
                            <b>Google Analytics</b> -analytiikkapalvelun
                            seurantaevästeen, jonka avulla seuraamme
                            palveluidemme käyttöä ja käyttäjien toimia
                            verkkopalvelussamme yleisellä tasolla.
                        </li>
                    </ul>

                    <h2>Sijaintitiedot</h2>

                    <p>
                        Evästeiden ohessa saatamme käyttää ip-osoitteen avulla
                        johdettua sijaintitietoa. IP-osoitteen avulla johdetun
                        sijaintiedon tarkkuus rajautuu pääsääntöisesti tiettyyn
                        kuntaan tai kaupunkiin. Sijaintitiedon avulla voimme
                        tarjota sinulle omaan sijaintiisi liittyvää ja
                        tarpeellista sisältöä tai palveluita.
                    </p>

                    <h2>Kolmansien osapuolten keräämät tiedot</h2>

                    <p>
                        Käyttäessäsi verkkopalveluamme, selaimesi saattaa pyytää
                        joitain palvelun käyttöön liittyviä tarpeellisia tietoja
                        kolmansien osapuolten palveluilta. Tietoja käytetään
                        esimerkiksi joidenkin erityisten toimintojen
                        toteuttamiseen, ulkoasun tuottamiseen tai tilastotiedon
                        keräämiseen. Kolmansien osapuolten palveluihin
                        lukeutuvat myös sosiaalisen median sekä analytiikka- ja
                        kävijämääräseurantaan liittyvät palvelut.
                    </p>

                    <p>Käytämme seuraavia kolmansien osapuolten palveluita:</p>

                    <ul>
                        <li>
                            <b>Google Analytics</b>, jonka avulla voimme seurata
                            palveluidemme käyttöä ja käyttäjien toimia
                            verkkopalvelussamme yleisellä tasolla. Katso{" "}
                            <a
                                href="https://www.google.com/analytics/learn/privacy.html?hl=fi"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Google Analyticsin tietosuojaseloste
                            </a>
                        </li>
                        <li>
                            <b>Logrocket</b>, jonka avulla voimme tietyissä
                            tapauksessa seurata palveluidemme käyttöä ja
                            käyttäjien toimia virhetilanteiden selvittämiseksi.
                            Katso{" "}
                            <a
                                href="https://logrocket.com/privacy/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Logrocket-palvelun tietosuojaseloste
                            </a>
                        </li>
                        {config.facebook_pixels &&
                        config.facebook_pixels.length > 0 && (
                            <li>
                                <b>Facebook</b>, jonka avulla voimme
                                tietyissä tapauksissa kohdentaa
                                markkinointiamme sinulle sosiaalisessa
                                mediassa. Katso{" "}
                                <a
                                    href="https://www.facebook.com/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Facebookin tietosuojaseloste ja
                                    -asetukset
                                </a>
                            </li>
                        )}
                    </ul>

                    <p>
                        Käyttäessäsi verkkopalveluamme ip-osoitteesi sekä
                        joissain tapauksissa tiedot käyttämästäsi selaimesta
                        sekä toimesi verkkopalvelussamme voi välittyä
                        kolmansille osapuolille. Kolmansien osapuolten
                        palveluiden käyttöön sovelletaan kunkin
                        palveluntarjoajan ehtoja, jotka voi tarkistaa kyseisestä
                        palvelusta. Emme vastaa kolmansien osapuolten
                        henkilötietojen keräämisestä tai käsittelystä.
                    </p>

                    <p>
                        Voit tarvittaessa rajoittaa ja estää kolmansille
                        osapuolille välitettävät tiedot selaimesi asetuksista.
                        Voit rajoittaa tietojen käyttöä, kuten mainosten
                        kohdentamista, kolmansien osapuolten palveluissa.
                    </p>

                    {config.third_party_services_cookie_policy && (
                        <div>
                            <p>
                                Tapahtumajärjestäjän ohjeistamana käytämme
                                lisäksi seuraavia kolmansien osapuuolten
                                paiveluita:
                            </p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: translationHelper(
                                        config.third_party_services_cookie_policy,
                                        i18n.language,
                                    ),
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default CookiePolicyPage;
